export const I18N_DE = {
    text: {
        all: 'Alle',
        my: 'Meine',
        movies: 'Filme',
        tvs: 'TV-Serien',
        home: 'Zuhause',
        next: 'Nächste',
        prev: 'Vorige',
        search: {
            placeholder: 'Suchbegriff eingeben'
        }
    }
}
