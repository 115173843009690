<template>
  <div v-if="knownFor.length" class="">
    <h4>Bekannt für</h4>
    <div class="known__for">
      <KnownForItem v-for="(movie, idx) of knownFor" v-bind:key="`kfi-${idx}`" :movie="movie" />
    </div>
  </div>
</template>

<script>

import KnownForItem from '@/components/MovieItem/KnownForItem.vue'
export default {
  name: 'KnownForCarousel',
  components: {
    KnownForItem
  },
  props: {
    person: Object
  },
  computed: {
    knownFor() {
      if(this.person.known_for_department === 'Acting') {
        return this.person.credits.cast.slice(0,5)
      }
      return this.person.credits.crew.slice(0,5)
    }
  }
}
</script>

<style lang="scss">
.known__for {
  display: flex;
  &>* {
    width: 154px;
    margin: 0 15px 15px 0;
    font-size: 12px;
  }
  img {
    filter: grayscale(60%);
    opacity: 0.8;
    height: 231px;
    width: 154px;
    &:hover {
      -webkit-filter: none;
      filter: grayscale(0);
      opacity: 1;
    }
  }
  h4 {
    margin: 0;
  }
}
</style>
