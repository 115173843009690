<template>
  <button @click="downloadM3u" class="play" :title="item.localPath">
    <h3>PLAY {{formatVideoSize}}</h3>
    <span v-if="item.automatch">*automatch</span>
  </button>
</template>

<script>

import FileSaver from 'file-saver';
export default {
  name: 'LocalFilesLink',
  components: {

  },
  props: {
    item: Object
  },
  computed: {
    formatVideoSize() {
      return this.formatBytes(this.item.videosize/1000)
    }
  },
  methods: {
    downloadM3u(){
      var blob = new Blob([this.item.localPath], {type: "application/audio/x-mpeg;charset=utf-8"});
      FileSaver.saveAs(blob, `MOOVY-${this.baseName(this.item.localPath)}.m3u`);
    },

    baseName(str) {
      return str.split('/').filter(function(e){return e}).reverse()[0];
    },

    // thanks to https://timber.io/snippets/bytes-to-gb-mb-kb-in-javascript/
    formatBytes (bytes) {
      if (bytes === 0) return '0 GB'
      if (isNaN(parseInt(bytes))) return bytes
      if (typeof bytes === 'string') bytes = parseInt(bytes)
      if (bytes === 0) return '0';
      const k = 1000;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      const dm = (i<3) ? 0 : 1;
      
      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    }

  }
}
</script>

<style lang="scss">
button.play {
  color: white;
  padding: 0 2em;
  background:
  linear-gradient(to bottom, #f82727 0%,#aa0303 100%);
}
</style>
