<template>
  <span>
    <GenreFilter v-for="(genre, idx) of getFilters.genres" v-bind:key="`genrefilter-${idx}`" :genre="genre" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

import GenreFilter from './GenreFilter.vue'
export default {
  name: 'GenreFilters',
  components: {
    GenreFilter
  },
  computed: {
    ...mapGetters([
      'getFilters'
    ]),
  }
}
</script>

<style lang="scss">

</style>
