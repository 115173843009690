<template>
  <span :class="`${itemClass} ${isInverted}`">
    <span @click="invertFilter">Genre: {{genre.name}}</span> <span @click="unsetFilter">&#10005;</span>
  </span>
</template>

<script>
export default {
  name: 'GenreFilter',
  data() {
    return {
      invert: false
    }
  },
  props: {
    genre: Object,
    itemClass: {
      default: 'text-slug',
      type: String
    }
  },
  computed: {
    isInverted() {
      if(this.invert === true){
        return 'text-slug--inverted'
      }
      return ''
    }
  },
  methods: {
    unsetFilter() {
      this.$store.commit('unsetFilter', {type: 'genres', item: this.genre})
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
      this.$router.push(
        { name: 'Home' }
      )
    },
    invertFilter() {
      this.genre.invert = !this.genre.invert
      this.invert = this.genre.invert
      this.$store.commit('unsetFilter', {type: 'genres', item: this.genre})
      this.$store.commit('setFilter', {type: 'genres', item: this.genre})
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
      this.$router.push(
        { name: 'Home' }
      )
    }
  }
}
</script>

<style lang="scss">

</style>
