<template>
  <span @click="setFilter" :class="itemClass">
    {{country.name}}
  </span>
</template>

<script>
export default {
  name: 'CountryLink',
  props: {
    country: Object,
    itemClass: {
      default: 'text-slug',
      type: String
    }
  },
  methods: {
    setFilter() {
      this.$store.commit('setFilter', {type: 'countries', item: this.country})
      this.$store.dispatch('clientPaginationChange', 1)
      this.$router.push(
        { name: 'Country', params: { id: this.country.iso_3166_1 } }
      )
    }
  }
}
</script>

<style lang="scss">

</style>
