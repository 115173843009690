<template>
  <router-link :to="{ name: 'MovieDetail', params: { id: movie.id, media_type: 'movie' }}" class="collection__item">
    <div class="img_wrapper">
    <img :src="posterPath" />
    </div>
    <p>{{movie.title}}<br>
       {{extractYear(movie)}}
    </p>
    <div class="play-icon">
      <LocalFilesLinks :id="movie.id" :media_type="videoType" additionalClass="play-icon"/>
    </div>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import { extractYear } from '../../mixins/extractYear.js'
import LocalFilesLinks from '@/components/Links/LocalFilesLinks.vue'
export default {
  name: 'CollectionItem',
  components: {
    LocalFilesLinks
  },
  props: {
    movie: Object
  },
  mixins: [
    extractYear
  ],
  computed: {
    ...mapGetters([
      'getState'
    ]),
    posterPath() {
      if(this.movie.poster_path){
        return 'https://image.tmdb.org/t/p/w92' + this.movie.poster_path
      }
      return '#'
    },
    videoType() {
      if(this.movie.media_type){
        return this.movie.media_type
      }
      return this.getState.resultType
    }
  }
}
</script>

<style lang="scss">
.collection__item {
  display: flex;
  position: relative;

  .play-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
