<template>
  <span @click="setFilter" :class="itemClass" :title="`${genre.name} id:${genre.id}`">
    {{genre.name}}
  </span>
</template>

<script>
export default {
  name: 'GenreLink',
  props: {
    genre: Object,
    itemClass: {
      default: 'text-slug',
      type: String
    }
  },
  methods: {
    setFilter() {
      this.genre.invert = false
      this.$store.commit('setFilter', {type: 'genres', item: this.genre})
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
      this.$router.push(
        { name: 'Home' }
      )
    }
  }
}
</script>

<style lang="scss">

</style>
