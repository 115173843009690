<template>
  <div class="form form__login">
    <div v-if="isLoggedIn">
       logged in as {{getUser.username}}
       <button type="button" @click="logout()">Logout</button>
    </div>
    <div v-else>
    <h1>Login</h1>
    <p>You have to login in order to view this content<br>
    <span class="dark">Registration is currently closed - but stay tuned...</span></p>
    <form action="" method="post">
      <input type="text" name="username" v-model="input.user" @keyup="keymonitor" placeholder="Username" /><br>
      <input type="password" name="password" v-model="input.pass" @keyup="keymonitor" placeholder="Password" /><br>
    </form>
    <button type="button" @click="login()" class="btn">
      <span v-if="busy">
        Verifying login data...
        <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 32 32">
          <g fill="#f82727">
            <path id="path1" transform="rotate(0,16,16) translate(0,0) scale(1,1)  " d="M15.819,6.8640137C10.837997,6.8640137 6.7860107,10.962036 6.7860107,16 6.7860107,21.038025 10.837997,25.136047 15.819,25.136047 20.800003,25.136047 24.852005,21.038025 24.852005,16 24.852005,10.962036 20.800003,6.8640137 15.819,6.8640137z M26.201004,6.4440308L23.373001,9.1410522C24.990005,10.962036 25.98201,13.359009 25.98201,16 25.98201,21.677002 21.430008,26.279053 15.819,26.279053 10.206009,26.279053 5.6560059,21.677002 5.6560059,16 5.6560059,13.473022 6.5610046,11.163025 8.0570068,9.3730469L5.026001,6.8980103C2.9880066,9.3670044 1.7580109,12.541016 1.7580109,16 1.7580109,23.842041 8.0660095,30.223022 15.819,30.223022 23.572006,30.223022 29.880005,23.842041 29.880005,16 29.880005,12.321045 28.477005,8.9710083 26.201004,6.4440308z M15.819,0C24.555008,0 31.638,7.1640015 31.638,16 31.638,24.835999 24.555008,32 15.819,32 7.0830078,32 0,24.835999 0,16 0,7.1640015 7.0830078,0 15.819,0z">
              <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0, 16, 16" to="360, 16, 16" dur="0.5s" repeatCount="indefinite" />
            </path>
          </g>
        </svg>
      </span>
      <span v-else>
        Login
      </span>
    </button>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  name: 'LoginForm',
  props: {
    msg: String
  },
  data() {
    return {
      input: {
        user: 'demo1',
        pass: 'demo1'
      },
      busy: false
    }
  },
  created () {

  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getUser'
    ])
  },
  methods: {
    keymonitor: function(event) {
      // console.log(event.key);
      if(event.key !== "Enter") {
        return
      }
      this.login()
    },
    login() {
      if(this.input.user === '' || this.input.pass === '') {
        console.log('insert username and password')
        return
      }
      this.busy = true
      this.$axios.post(
        `${process.env.VUE_APP_API_URL}userdata`,
        this.toUrlEncoded({
          user: this.input.user,
          pass: this.input.pass,
          pid: 2, // TODO move config to api
          logintype: 'login'
        }),
        { withCredentials: true }
      )
      .then(response => {
        this.busy = false
        this.$store.commit('clearResults')
        this.$store.commit('receiveUserData', response)
        this.$store.dispatch('clientPaginationChange', 1)
      })
    },
    logout() {
      this.busy = true
      this.$axios.post(
        `${process.env.VUE_APP_API_URL}userdata`,
        this.toUrlEncoded({
          logintype: 'logout'
        }),
        { withCredentials: true }
      )
      .then(response => {
        this.busy = false
        this.$store.commit('clearResults')
        this.$store.commit('receiveUserData', response)
        this.$store.dispatch('clientPaginationChange', 1)
      })
    },
    toUrlEncoded(obj) {
      return Object.keys(obj).map(
        k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
      ).join('&')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
