<template>
  <div v-if="hasPlayItems" :class="additionalClass">
    <LocalFilesLink v-for="(playItem, idx) of getPlayItems" v-bind:key="`pi-${idx}`" :item="playItem" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import LocalFilesLink from '@/components/Links/LocalFilesLink.vue'

export default {
  name: 'LocalFilesLinks',
  components: {
    LocalFilesLink
  },
  props: {
    id: Number,
    media_type: String,
    additionalClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'getPlayItemsFor'
    ]),
    hasPlayItems() {
      return this.getPlayItems.length > 0
    },
    getPlayItems() {
      // console.log(this.getPlayItemsFor(this.id, this.media_type))
      return this.getPlayItemsFor(this.id, this.media_type)
    }
  },
  methods: {
    fetchMovie(){
      
    }
  }
}
</script>

<style lang="scss">
.play-icon {
  .play {
    padding: 0;

    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid $red;

    transform: rotate(-45deg);
    position: relative;
    top: -18px;
    right: -9px;
  


    // todo: add play icon
    h3 {
      display: none;
    }
    span {
      display: none;
    }
  }
  &>button {
    display: none;
    &:first-child {
      display: block;
    }
  }
}

.tinyWithSize {
  .play {
    h3 {
      font-size: 12px;
      padding: 3px;
      margin: 0;
    }
  }
}
</style>
