export const I18N_EN = {
    text: {
        all: 'All',
        my: 'My',
        movies: 'Movies',
        tvs: 'TV-Series',
        home: 'Home',
        next: 'Next',
        prev: 'Prev',
        search: {
            placeholder: 'Enter searchterm'
        }
    }
}
