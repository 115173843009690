<template>
  <div v-if="collection">
    <div v-if="isFetched">
      <h3>{{collection.name}}</h3>
      <CollectionItem v-for="(movie, idx) of movies.parts" v-bind:key="`ci-${idx}-${movie.id}`" :movie="movie" />
    </div>
    <div v-else>
       loading collection...
    </div>
  </div>
</template>

<script>
import CollectionItem from '@/components/MovieItem/CollectionItem.vue'
export default {
  name: 'InCollection',
  components: {
    CollectionItem
  },
  data() {
    return {
      isFetched: false,
      movies: null
    }
  },
  props: {
    collection: Object
  },
  created(){
    if (this.collection) {
      this.fetchCollection(this.collection.id);
    }
  },
  methods: {
    fetchCollection(){
      const url = [
          this.$store.state.tmdbapi.domain,
          'collection/', this.collection.id,
          '?api_key=', this.$store.state.tmdbapi.apiKey,
          '&language=', this.$store.state.tmdbapi.language
      ].join('')
      // console.log(url)

      this.$axios.get(url, { withCredentials: false })
      .then(function(resp){
          // resp.data.backdrop_path = null
          this.movies = resp.data
          // console.log(this.movies)
          this.isFetched = true
      }.bind(this))
      .catch(function() {
        this.$router.push({ name: '404' });
      }.bind(this));
    }
  }
}
</script>

<style lang="scss">

</style>
