<template>
  <router-link :to="{ name: 'PersonDetail', params: { id: person.id }}" :class="itemClass">
    {{person.name}}
  </router-link>
</template>

<script>
export default {
  name: 'PersonLink',
  props: {
    person: Object,
    itemClass: {
      default: 'text-slug',
      type: String
    }
  }
}
</script>

<style lang="scss">

</style>
