<template>
  <v-clamp :max-lines="maxLines" autoresize>
    {{text}}
    <template #after="{ toggle, expanded, clamped }">
      <br>
      <span v-if="clamped" class="text-slug" @click="toggle" >mehr anzeigen</span>
      <span v-if="expanded" class="text-slug" @click="toggle" >weniger anzeigen</span>
    </template>
  </v-clamp>
</template>

<script>
import VClamp from 'vue-clamp'
export default {
  name: 'ClampedText',
  components: {
    VClamp
  },
  props: {
    text: String,
    maxLines: {
      type: Number,
      default: 6
    }
  }
  /*,
  computed: {
    nl2brText() {
      return this.text.replace('\n', '<br>')
    }
  }
  */
}
</script>

<style lang="scss">
.person__details--content span span { white-space: pre-line; }
.movie_detail--plot span span { white-space: pre-line; }
</style>
