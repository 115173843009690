<template>
  <div>
    <nav class="nav nav__credits">
      <div :class="`nav__item ${(section === 'cast') ? 'active':''}`" @click="showCast">Cast</div>
      <div :class="`nav__item ${(section === 'crew') ? 'active':''}`" @click="showCrew">crew</div>
      <div :class="`nav__item ${(section === 'details') ? 'active':''}`" @click="showDetails">details</div>
    </nav>
    <div v-if="section === 'cast'">
        <div class="cast-list text-sluglist">
          <PersonLink v-for="(person, idx) of credits.cast" v-bind:key="`moviecredits-${idx}`" :person="person" />
         </div>
    </div>
    <div v-if="section === 'crew'">
      <CrewSection heading="Directors" :people="directors" />
      <CrewSection heading="Producers" :people="producers" />
      <CrewSection heading="Writers" :people="writers" />
      <CrewSection heading="Editors" :people="editors" />
      <CrewSection heading="Cinematography" :people="cameras" />
      <CrewSection heading="Production design" :people="productionDesigners" />
      <CrewSection heading="Set decoration" :people="setDecorators" />
      <CrewSection heading="Composer" :people="sound" />
      <CrewSection heading="Costumes" :people="costumes" />
    </div>
    <div v-if="section === 'details'">
       details
    </div>
  </div>
</template>

<script>
import PersonLink from '@/components/Links/PersonLink.vue'
import CrewSection from '@/components/MovieItem/CrewSection.vue'
export default {
  name: 'MovieCredits',
  components: {
    PersonLink,
    CrewSection
  },
  data() {
    return {
      limit: 3,
      section: 'cast'
    }
  },
  props: {
    credits: Object
  },
  computed: {
    limitedCastList () {
      return this.castList.slice(0, this.limit)
    },
    directors () { return this.crewDepartmentFilter('Directing') },
    producers () { return this.crewDepartmentFilter('Production') },
    writers () { return this.crewDepartmentFilter('Writing') },
    editors () { return this.crewDepartmentFilter('Editing') },
    cameras () { return this.crewDepartmentFilter('Camera') },

    productionDesigners () { return this.crewJobFilter('Production Design') },
    setDecorators () { return this.crewJobFilter('Set Decoration') },
    sound () { return this.crewDepartmentFilter('Sound') },
    costumes () { return this.crewDepartmentFilter('Costume & Make-Up') }
    
  },
  methods: {
    showCast() { this.section = 'cast' },
    showCrew() { this.section = 'crew' },
    showDetails() { this.section = 'details' },

    toggleFilter(event, argument) {
        console.log(event)
        console.log(argument)
    },
    crewDepartmentFilter(departmentName) {
      return this.credits.crew.filter(function (el) {
        return el.department === departmentName
      })
    },
    crewJobFilter(jobName) {
      return this.credits.crew.filter(function (el) {
        return el.job === jobName
      })
    }
  }
}
</script>

<style lang="scss">
.nav__credits {
    display: flex;
    .active {
        font-weight: bold;
    }
}

.nav__credits {
  width: 100%;
  border-bottom: 1px solid #4C555E;
  &>* {
    padding: 1em;
    text-transform: uppercase;
  }
  &>.active {
    border-bottom: 1px solid white;
  }
}

.cast-list {
  padding-top: 20px;
}
</style>
