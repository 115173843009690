<template>
  <div class="module__content">
    <div v-if="showLoginForm">
      <LoginForm />
    </div>
    <div v-else>
      <div v-if="userNothingImported">
        <HowToImport />
      </div>
      <div v-else>
        <div class="list-style-selector">
          <span @click="setListStyle0" :class="activeClass0">🎬</span>
          <span @click="setListStyle1" :class="activeClass1"></span>
          <span @click="setListStyle2" :class="activeClass2">⚏</span>
          <span @click="setListStyle3" :class="activeClass3">☰</span>
          <!--span @click="setListStyle4" :class="activeClass4"></span-->
        </div>
        <div class="pagination">
          total {{getState.pagination.totalResults}} items
          <span @click="prevPage" :class="`text-slug ${cssClassPrevPage}`">{{$t('text.prev')}} &lt;</span>
          <span @click="randomPage" :class="`text-slug ${cssClassRandomPage}`">random page</span>
          <span> Page {{getState.pagination.client.currentPage}} / {{getState.pagination.client.totalPages}} </span>
          <span @click="nextPage" :class="`text-slug ${cssClassNextPage}`">{{$t('text.next')}} &gt;</span>
        </div>
        <!--pre>
        // debug tricky api/client pagination
        pagination: {
          totalResults: {{getState.pagination.totalResults}},
          totalResultsKnown: {{getState.pagination.totalResultsKnown}},
          api: {
            currentPage: {{getState.pagination.api.currentPage}},
            itemsPerPage: {{getState.pagination.api.itemsPerPage}},
            totalPages: {{getState.pagination.api.totalPages}}
          },
          client: {
            currentPage: {{getState.pagination.client.currentPage}},
            itemsPerPage: {{getState.pagination.client.itemsPerPage}},
            totalPages: {{getState.pagination.client.totalPages}},
            resultIndex: {
              first: {{getState.pagination.client.resultIndex.first}},
              last: {{getState.pagination.client.resultIndex.last}}
            }
          }
        }
        </pre-->
        <div v-if="getMovieResultList.length" :class="`item__list item__list-movies item__list-movies--${getState.listStyle}`" v-hotkey="keyBrowse">
          <MovieListItem v-for="movie of getMovieResultList" v-bind:key="movie.uid" :movie="movie" />
          <div class="navigation__links next__link" @click="nextPage" ref="arrowNext" title="use right arrow key" v-if="hasNextPage()">
            <span></span>
          </div>
          <div class="navigation__links prev__link" @click="prevPage" ref="arrowPrev" title="use left arrow key" v-if="hasPrevPage()">
            <span></span>
          </div>
        </div>
        <div v-else>
          <br><br><br><strong>TODO:</strong> handle zero results

          <br>
          <span v-if="getAnyFilterActive">
              consider to 
              <span @click="resetFilters" class="text-slug">Reset all filters</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MovieListItem from './MovieListItem.vue'
import LoginForm from './LoginForm.vue'
import HowToImport from '@/components/Content/HowToImport.vue'

const API_TMDB = 1 // defined in '@/store/index.js'
export default {
  components: {
    MovieListItem,
    LoginForm,
    HowToImport
  },
  name: 'MovieResultList',
  computed: {
    ...mapGetters([
      'getMovieResultList',
      'isLoggedIn',
      'getState',
      'getAnyFilterActive'
    ]),
    showLoginForm(){
      if(this.isLoggedIn) {
        return false
      }
      if (this.getState.apiMode === API_TMDB) {
        return false
      }
      return true
    },
    userNothingImported(){
      if (this.getState.apiMode === API_TMDB) {
        return false
      }
      if (Object.keys(this.getState.user.movies).length > 0) {
        return false
      }
      return true
    },
    activeClass0(){ return (this.getState.listStyle === 0) ? 'active' : '' },
    activeClass1(){ return (this.getState.listStyle === 1) ? 'active' : '' },
    activeClass2(){ return (this.getState.listStyle === 2) ? 'active' : '' },
    activeClass3(){ return (this.getState.listStyle === 3) ? 'active' : '' },
    // activeClass4(){ return (this.getState.listStyle === 4) ? 'active' : '' },

    keyBrowse () {
      return {
        // 'esc+ctrl' is OK.
        'right': this.nextPage,
        'left': this.prevPage,
        'r': this.randomPage
      }
    },
    cssClassNextPage() {
      return (this.hasNextPage()) ? '' : 'disabled'
    },
    cssClassPrevPage() {
      return (this.hasPrevPage()) ? '' : 'disabled'
    },
    cssClassRandomPage() {
      return (this.hasRandomPage()) ? '' : 'disabled'
    }
  },
  methods: {
    setListStyle0() { this.setListStyle(0) },
    setListStyle1() { this.setListStyle(1) },
    setListStyle2() { this.setListStyle(2) },
    setListStyle3() { this.setListStyle(3) },
    // setListStyle4() { this.setListStyle(4) },
    setListStyle(arg) {
      if (this.$store.state.listStyle === arg) {
        // no change
        return
      }
      this.$store.commit('changeListStyle', arg)
      // TODO: improve chossing the new resultpage
      //       make sure our first result of old paginated results will appear on new page
      this.$store.dispatch('clientPaginationChange', this.$store.state.pagination.client.currentPage)
    },
    prevPage() {
      if (this.hasPrevPage() === false) {
        return
      }
      const arrow = this.$refs.arrowPrev
      arrow.classList.add('hover');
      this.$store.dispatch('decrementClientPagination')
      setTimeout(function(){
        arrow.classList.remove('hover');
      }, 600)
    },
    nextPage() {
      if (this.hasNextPage() === false) {
        return
      }
      const arrow = this.$refs.arrowNext
      arrow.classList.add('hover');
      this.$store.dispatch('incrementClientPagination')
      setTimeout(function(){
        arrow.classList.remove('hover');
      }, 600)
    },
    hasNextPage() {
      return this.getState.pagination.client.totalPages > this.getState.pagination.client.currentPage
    },
    hasPrevPage() {
      return this.getState.pagination.client.currentPage > 1
    },
    hasRandomPage() {
      return this.getState.pagination.client.totalPages > 1
    },
    randomPage() {
      if (this.hasRandomPage() === false) {
        return
      }
      this.$store.dispatch(
        'clientPaginationChange',
        Math.floor(Math.random() * this.$store.state.pagination.client.totalPages) + 1
      )
    },
    resetFilters: function() {   // TODO move to mixin (@see duplicate code in App.vue)
      this.$store.commit('resetFilters')
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
    }
  },
  mounted(){
    this.$store.dispatch('clientPaginationChange', this.$store.state.pagination.client.currentPage)
  },
}
</script>

<style lang="scss">
.item__list-movies {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.list-style-selector span {
    font-size: 12px;
    padding: 0 5px 5px 5px;
    line-height: 100%;
    font-family: "fontello";
    color: #4C555E;
    font-size: 23px;
    line-height: 100%;
    overflow: hidden;
    transition: color 0.5s ease 0s;
    width: 450x;
    height: 40px;

    &:hover {
      color: white;
    }
    &.active {
      color: white;
    }
}

.pagination {
  .disabled {
    opacity: 0.5;
  }
}


.navigation__links {
  position: absolute;
  height: 50%;
  margin-top: 15%;
  width: 60px;
  opacity: 0.05;
  transition: opacity 0.5s ease 0s;

  span {
  position: fixed;
    width: 0;
    height: 0;
    margin-top: 80px;
    display: block;
    border-style: solid;
    border-width: 100px 0 100px 60px;
    border-color: transparent transparent transparent $red;
  }

  &.next__link {
    right: -60px;
  }

  &.prev__link {
    left: -60px;
    span {
      transform: rotate(180deg);
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.3;
    
  }
  &.hover {
    transition: opacity 0s ease 0s;
    opacity: 0.3;
    
  }
}
</style>