import Vue from 'vue'
import Vuex from 'vuex'

// TODO: remove duplicate code @see axios + nprogress in main.js
import axios from 'axios'
import NProgress from '../../node_modules/nprogress/nprogress.js'

Vue.use(Vuex)

const API_TMDB = 1
const API_MOOVY = 2

// tmdb strictly separates movies from tv shows
const RESULT_TYPE_MOVIE = 'movie'
const RESULT_TYPE_TV = 'tv'

export default new Vuex.Store({
  state: {
    apiDomain: process.env.VUE_APP_API_URL,
    user: {
      login: false,
      username: '',
      id: '',
      movies: [],
      lists: [],
      users:[],
      permissions: []
    },
    apiMode: API_MOOVY,
    resultType: RESULT_TYPE_MOVIE,
    tmdbapi: {
      apiKey: '532c0210ab58b23c929df9c17482e6da',
      language: 'de',
      domain: 'https://api.themoviedb.org/3/'
    },
    moovyapi: {
      apiKey: '532c0210ab58b23c929df9c17482e6da',
      language: 'de',
      domain: process.env.VUE_APP_API_URL
    },
    apiUrls: {
      genres: 'genre/movie/list', // consider to merge with '/genre/tv/list'
      defaultList: 'trending/{type}/day',
      search: 'search/{type}',
      discover: 'discover/{type}',
      movieDetail: '{type}/'
    },
    errors: [],
    apiResultPages: {},
    triggerRenderSelection: false,
    moviesToRender: [],
    listStyle: 0,
    listStyles: [
      {
        itemsPerPage: 3,
        posterPrefix: 'https://image.tmdb.org/t/p/w342'
      },
      {
        itemsPerPage: 4,
        posterPrefix: 'https://image.tmdb.org/t/p/w154'
      },
      {
        itemsPerPage: 18,
        posterPrefix: 'https://image.tmdb.org/t/p/w154'
      },
      {
        itemsPerPage: 10,
        posterPrefix: 'https://image.tmdb.org/t/p/w154'
      }
    ],
    // depending on listStyle we have different pagination on API-response and client
    pagination: {
      totalResults: 0,
      totalResultsKnown: false,
      api: {
        currentPage: 1,
        itemsPerPage: 20, // hopefully this will not change. its really tricky to handle all stuff without knowing this
        totalPages: 0
      },
      client: {
        currentPage: 1,
        itemsPerPage: 3,  // default state.listStyles[0].itemsPerPage
        totalPages: 1,
        resultIndex: {
          first: 0,
          last: 0
        }
      }
    },
    filters: {
      searchterm: '',
      countries: [],
      people: [],
      genres: []
    }
  },
  mutations: {
    receiveUserData (state, apiResponse) {
      const previousState = state.user.login

      // we have login state in all api responses
      state.user.login = apiResponse.data.login
      state.user.username = apiResponse.data.username
      state.user.id = apiResponse.data.id
  
      if(apiResponse.data.fullresponse === true) {
        // full user data is only appended to specific api responses
        state.user = apiResponse.data
      }
      // TODO: handle use case where user logs in as another user in another tab
      if (previousState === state.user.login) {
         return
      }
      // console.log('login state changed')

      if (state.user.msg === null) {
        return
      }
      Vue.notify({
        group: 'foo',
        text: state.user.msg.text,
        type: state.user.msg.type,
      })
    },

    setApiToMoovy (state) {
      state.apiMode = API_MOOVY
    },

    setApiToTmdb (state) {
      state.apiMode = API_TMDB
    },

    setResultTypeToMovie (state) {
      state.resultType = RESULT_TYPE_MOVIE
    },

    setResultTypeToTv (state) {
      state.resultType = RESULT_TYPE_TV
    },
    changeLocale(state, langKey) {
      state.tmdbapi.language = langKey
    },
    setTriggerRenderSelection (state, triggerState) {
      state.triggerRenderSelection = triggerState
    },

    receiveMovieResults (state, apiResponse) {
      if(apiResponse !== null) {
        state.apiResultPages[`page-${apiResponse.data.page}`] = apiResponse.data
        state.pagination.totalResults = apiResponse.data.total_results
        state.pagination.totalResultsKnown = true
        state.pagination.api.totalPages = apiResponse.data.total_pages
        state.pagination.client.totalPages = Math.ceil(state.pagination.totalResults / state.pagination.client.itemsPerPage)
      }
      if (state.triggerRenderSelection === false) {
        return
      }

      // console.log('triggerRenderSelection()')

      const apiPageIndexFirstClientItem = Math.ceil(
        state.pagination.client.resultIndex.first /
        state.pagination.api.itemsPerPage
      )
      const apiPageIndexLastClientItem = Math.ceil(
        state.pagination.client.resultIndex.last /
        state.pagination.api.itemsPerPage
      )
      state.moviesToRender = []
      for(let i = apiPageIndexFirstClientItem; i <= apiPageIndexLastClientItem; i++){
        // console.log('check api page ', i)
        if(typeof state.apiResultPages[`page-${i}`] == 'undefined') {
          // console.log('ERROR: we do not have results of required api page index', i)
          continue
        }
        // console.log('SUCCESS: we have the page rusult of api page index ', i)
        // console.log(state.apiResultPages[`page-${i}`].results)
        const apiPageStartIndex = state.pagination.api.itemsPerPage * (state.apiResultPages[`page-${i}`].page - 1) + 1
        // console.log('first index of this resultpage: ', apiPageStartIndex)

        state.apiResultPages[`page-${i}`].results.forEach(function(resultItem, idx) {
          if( apiPageStartIndex + idx < state.pagination.client.resultIndex.first) {
            return
          }
          if( apiPageStartIndex + idx > state.pagination.client.resultIndex.last) {
            return
          }
          state.moviesToRender.push(resultItem)
          //console.log(resultItem, idx)
        })
      }
    },

    changeListStyle (state, listStyleIndex) {
      // console.log('new list-style-index', listStyleIndex)

      if (state.listStyle === listStyleIndex) {
        // no change
        return
      }

      if (state.listStyles[state.listStyle].itemsPerPage === state.listStyles[listStyleIndex].itemsPerPage) {
        // no change in items per page - no repagination reqired
        state.listStyle = listStyleIndex
        return
      }

      // we have to recalculate client side pagination
      // choose the page where the first item of previous listStyle ends up in new pagination
      const firstItemIndex = state.pagination.client.resultIndex.first
      const newPage = Math.ceil(firstItemIndex / state.listStyles[listStyleIndex].itemsPerPage)
      state.pagination.client.currentPage = (newPage < 1) ? 1 : newPage
      state.pagination.client.itemsPerPage = state.listStyles[listStyleIndex].itemsPerPage
      state.listStyle = listStyleIndex

      state.pagination.client.resultIndex.first = state.pagination.client.currentPage * state.pagination.client.itemsPerPage - state.pagination.client.itemsPerPage + 1
      state.pagination.client.resultIndex.last = state.pagination.client.resultIndex.first + state.pagination.client.itemsPerPage - 1

      if(state.pagination.totalResults > 0 && state.pagination.client.resultIndex.last > state.pagination.totalResults ) {
        state.pagination.client.resultIndex.last = state.pagination.totalResults
      }


    },
    
    currentClientPage (state, requestedPage) {
      // console.log('currentClientPage request', requestedPage)
      state.pagination.client.currentPage = (requestedPage < 1) ? 1 : requestedPage

      state.pagination.client.currentPage = (requestedPage > state.pagination.client.totalPages && state.pagination.client.currentPage > 0)
        ? (state.pagination.client.totalPages < 1) ? 1 : state.pagination.client.totalPages
        : state.pagination.client.currentPage

      // console.log('currentClientPage validated', state.pagination.client.currentPage)

      state.pagination.client.resultIndex.first = state.pagination.client.currentPage * state.pagination.client.itemsPerPage - state.pagination.client.itemsPerPage + 1
      state.pagination.client.resultIndex.last = state.pagination.client.resultIndex.first + state.pagination.client.itemsPerPage - 1

      if(state.pagination.totalResults > 0 && state.pagination.client.resultIndex.last > state.pagination.totalResults ) {
        state.pagination.client.resultIndex.last = state.pagination.totalResults
      }
      // console.log('xxxxxxxxxxxxxxx')
    },

    setFilter: function(state, filter){
      state.pagination.totalResultsKnown = false
      state.pagination.totalResults = 0
      state.pagination.api.currentPage = 1
      state.pagination.client.currentPage = 1
      if(filter.type === 'countries') {
        state.filters.countries = [filter.item]
        return
      }
      if(filter.type === 'genres') {
        state.filters.genres.push(filter.item)
        // console.log('genre filters', state.filters.genres)
        return
      }
      if(filter.type === 'text') {
        state.filters.searchterm = filter.item
        // console.log('text filter', state.filters.searchterm)
        return
      }
      // console.log('setFilter() not implemented yet for filter', filter)
    },
    unsetFilter: function(state, filter) {
      if(filter.type === 'genres') {
        state.filters.genres = state.filters.genres.filter(function( obj ) {
          return obj.id !== filter.item.id
        })
      }
      if(filter.type === 'text') {
        state.filters.searchterm = ''
        return
      }
    },
    resetFilters: function(state) {
      Vue.set(state, 'filters', {
        searchterm: '',
        countries: [],
        people: [],
        genres: []
      })
    },
    clearResults: function (state) {
      state.pagination.totalResultsKnown = false
      state.pagination.totalResults = 0
      state.pagination.api.currentPage = 1
      state.pagination.api.totalPages = 0
      state.pagination.client.currentPage = 1
      state.pagination.client.totalPages = 1
      state.pagination.client.resultIndex.first = 0
      state.pagination.client.resultIndex.last = 0
      state.apiResultPages = {}
      state.moviesToRender = []
    }
  },
  actions: {
    fetchUserStatus: function (context) {
        axios.get(`${context.state.apiDomain}userdata`, {withCredentials: true})
        .then(response => {
          context.commit('receiveUserData', response)
        })
        .catch(e => {
          context.state.errors.push(e)
        })
    },

    fetchApiData: function (/*context*/) {
      // context.dispatch('fetchUserStatus')
      // context.dispatch('fetchApiDataMovies')
    },

    incrementClientPagination: function(context) {
      context.dispatch('clientPaginationChange', context.state.pagination.client.currentPage + 1)
    },

    decrementClientPagination: function(context) {
      context.dispatch('clientPaginationChange', context.state.pagination.client.currentPage - 1)
    },

    clientPaginationChange: function(context, pageNumber) {

      // set current client page and calculate first + last index
      context.commit('currentClientPage', pageNumber)

      // determine all pages we need from api based on client pagination
      const requiredApiPageFirst = Math.ceil(
        context.state.pagination.client.resultIndex.first /
        context.state.pagination.api.itemsPerPage
      )
      const requiredApiPageLast = Math.ceil(
        context.state.pagination.client.resultIndex.last /
        context.state.pagination.api.itemsPerPage
      )

      // console.log('requiredApiPageFirst yyy', requiredApiPageFirst)
      // console.log('requiredApiPageLast yyy', requiredApiPageLast)

      // wait with rendering of resultpage until all api requests has finished
      context.commit('setTriggerRenderSelection', false)

      // TODO: remove duplicate code!!!! @see main.js
      axios.interceptors.request.use(config => {
        document.querySelector('.backdrop').style.visibility = 'visible';
        // document.querySelector('.backdrop').style.opacity = 0.5;
        NProgress.start()
        return config
      })
      axios.interceptors.response.use(response => {
        NProgress.done()
        // document.querySelector('.backdrop').style.opacity = 0.5;
        document.querySelector('.backdrop').style.visibility = 'hidden';
        return response
      })

      for(let i = requiredApiPageFirst; i <= requiredApiPageLast; i++){
          // console.log('need api page ', i)
          if(typeof context.state.apiResultPages[`page-${i}`] !== 'undefined') {
            // console.log('results for this page already exists', i)
            if(i === requiredApiPageLast) {
              context.commit('setTriggerRenderSelection', true)
              context.commit('receiveMovieResults', null)
            }
            continue
          }

          axios.get(
            this.getters.getCurrentResultsUrl(i),
            { withCredentials: (context.state.apiMode === API_TMDB) ? false : true }
          )
          .then(response => {
            if(i === requiredApiPageLast) {
              context.commit('setTriggerRenderSelection', true)
            }
            context.commit('receiveMovieResults', response)
            if(typeof response.data.user_state !== 'undefined') {
              context.commit('receiveUserData', {data: response.data.user_state})
            }
          })
          .catch(e => {
            context.state.errors.push(e)
            if(i === requiredApiPageLast) {
              context.commit('setTriggerRenderSelection', true)
              context.commit('receiveMovieResults', null)
            }
          })
          
      }
    }
  },
  getters: {
    isLoggedIn: state => state.user.login,
    getUser: state => state.user,
    getState: state => state,
    getFilters: state => state.filters,
    getCurrentListStyle: state => state.listStyles[state.listStyle],
    getMovieResultList: state => (state.moviesToRender),
    // isUserSelected: (state) => (userId) => { return state.selectedUsers.indexOf(userId) !== -1 },
    // isProductSelected: (state) => (productId) => { return state.selectedProduct === productId },
    getUserList: state => state.users,
    getCurrentResultsUrl: (state, getters) => (pageIndex) => {
      const apiConf = (state.apiMode === API_TMDB) ? 'tmdbapi' : 'moovyapi'
      let urlParts = [ state[apiConf].domain ]
      switch (true) {
        case (getters.getAnyFilterActive === false):
          urlParts.push(getters.substituteUrlParams(state.apiUrls.defaultList))
          break
        case (state.filters.searchterm !== ''):
          urlParts.push(getters.substituteUrlParams(state.apiUrls.search))
          break
        default:
          urlParts.push(getters.substituteUrlParams(state.apiUrls.discover))
      }
      urlParts = urlParts.concat([
        '?api_key=', state[apiConf].apiKey,
        '&language=', state[apiConf].language,
        '&page=', pageIndex,
        '&include_video=true',
        '&append_to_response=credits'
      ])
      if (getters.getAnyFilterActive === false) {
        return urlParts.join('')
      }

      if(state.filters.countries.length > 0){
        urlParts.push(
          `&regions=${state.filters.countries.map(function(c){return c.iso_3166_1}).join('%2C')}`
        )
      }

      if(state.filters.searchterm !== ''){
        urlParts.push(
          `&query=${state.filters.searchterm}`
        )
      }

      if(state.filters.genres.length > 0){
        // console.log('genre filters', state.filters.genres)
        urlParts.push(
          `&with_genres=${state.filters.genres.filter(function( obj ) {
              return obj.invert !== true
          }).map(function(g){return g.id}).join(',')}`
        )
        urlParts.push(
          `&without_genres=${state.filters.genres.filter(function( obj ) {
              return obj.invert === true
          }).map(function(g){return g.id}).join(',')}`
        )
      }
      // console.log('TODO getCurrentResultsUrl()', getters.getAnyFilterActive)
      return urlParts.join('')
    },
    getAnyFilterActive: state => {
      if(state.filters.searchterm !== '') return true
      if(state.filters.countries.length > 0) return true
      if(state.filters.people.length > 0) return true
      if(state.filters.genres.length > 0) return true
      return false
    },
    
    substituteUrlParams: (state) => (url) => {
      return url.replace('{type}', state.resultType)
    },
    
    getPlayItemsFor: (state) => (itemId, itemType) => {
      if(typeof state.user.movies[`${itemType}-${itemId}`] === 'undefined') {
        return []
      }
      return state.user.movies[`${itemType}-${itemId}`]
    }
  },
  modules: {
  }
})
