<template>
  <div>
    <div v-if="isFetched">
      <Backdrop :backdropPath="movie.backdrop_path" />
      <div :class="`movie__detail ${(movie.backdrop_path) ? 'movie__detail--has-backdrop' : ''}`">
        <div class="movie_detail--poster">
          <EnlargeableImage :src="posterPath" :src_large="posterPathFull" />
        </div>
        <div class="movie_detail--content">
          <h1>{{title}} <span class="year">({{extractYear(movie)}})</span></h1>
          <div v-if="directors">
            directed by 
            
            <PersonLink v-for="(person, idx) of directors" v-bind:key="`mlia-${idx}`" :person="person" itemClass='link' />
          </div>
          <div class="movie_detail--contentinner">
            <div>
              <i v-if="movie.tagline">{{movie.tagline}}</i>
              <p v-if="movie.overview" class="movie_detail--plot">
                <ClampedText :text="movie.overview" />
              </p>
              <p v-else>Kein Plot verfügbar</p>

              <MainCastCarousel :castList="mainCast" />
              <MovieCredits :credits="movie.credits" />

              <h4>More info</h4>
              <a :href="`https://www.imdb.com/title/${movie.imdb_id}`" target="_blank" class="text-slug">IMDB</a>
              <a :href="`https://www.themoviedb.org/movie/${movie.id}/`" target="_blank" class="text-slug">TMDB</a>
            </div>
            <aside class="movie_detail--additional">
              <LocalFilesLinks :id="id" :media_type="media_type" />
              <h4>rating {{movie.vote_average}}</h4>
              <h4>Runtime {{movie.runtime}} min</h4>
              <h4>Genres</h4>
              <p>
                <GenreLink v-for="genre of movie.genres" v-bind:key="genre.id" :genre="genre" />
              </p>
              <h4>Countries</h4>
              <p>
                <CountryLink v-for="country of movie.production_countries" v-bind:key="country.iso_3166_1" :country="country" />
              </p>
              <InCollection :collection="movie.belongs_to_collection" />
            </aside>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      fetching...
    </div>
    <router-link :to="{ name: 'Home' }" class="">back to list</router-link>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { extractYear } from '../mixins/extractYear.js'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';

// import CastList from './MovieItem/CastList.vue'
import MovieCredits from '@/components/MovieItem/MovieCredits.vue'
import MainCastCarousel from '@/components/MovieItem/MainCastCarousel.vue'
import InCollection from '@/components/MovieItem/InCollection.vue'
import Backdrop from '@/components/Backdrop.vue'
import GenreLink from '@/components/Links/GenreLink.vue'
import PersonLink from '@/components/Links/PersonLink.vue'
import CountryLink from '@/components/Links/CountryLink.vue'
import LocalFilesLinks from '@/components/Links/LocalFilesLinks.vue'
import ClampedText from '@/components/ClampedText.vue'
// available image sizes: https://www.themoviedb.org/talk/53c11d4ec3a3684cf4006400
export default {
  name: 'MovieDetail',
  mixins: [
    extractYear
  ],
  components: {
    MovieCredits,
    MainCastCarousel,
    Backdrop,
    GenreLink,
    PersonLink,
    CountryLink,
    EnlargeableImage,
    InCollection,
    ClampedText,
    LocalFilesLinks
  },
  data() {
    return {
      isFetched: false,
      movie: null
    }
  },
  props: {
    id: Number,
    media_type: String,
  },
  computed: {
    ...mapGetters([
      'isUserSelected',
      'getState'
    ]),
    posterPath() {
      if(this.movie.poster_path){
        return  'https://image.tmdb.org/t/p/w342' + this.movie.poster_path
      }
      return '//:0'
    },
    posterPathFull() {
      if(this.movie.poster_path){
        return  'https://image.tmdb.org/t/p/original' + this.movie.poster_path
      }
      return '//:0'
    },
    releaseYear() {
      if(this.movie.release_date){
        return this.movie.release_date
      }
      return ''
    },
    voteAvarage() {
      return this.movie.vote_average
    },
    year() {
      if(this.movie.release_date) {
        return this.movie.release_date.substring(0,4)
      }
      if(this.movie.first_air_date && this.movie.last_air_date) {
        return `${this.movie.first_air_date.substring(0,4)} - ${this.movie.last_air_date.substring(0,4)}`
      }
      if(this.movie.first_air_date) {
        return this.movie.first_air_date.substring(0,4)
      }
      return 'TODO: check year'

    },
    title() {
      if(this.movie.title){
        return this.movie.title
      }
      if(this.movie.name){
        return this.movie.name
      }
      return 'TODO: check title'
    },
    mainCast() {
      return this.movie.credits.cast.slice(0, 4)
    },
    directors() {
      if(!this.movie.credits){
        return
      }
      if(!this.movie.credits.crew){
        return
      }
      return this.movie.credits.crew.filter(function (el) {
        return el.department === 'Directing' && el.job === 'Director'
      })
    }
  },
  watch: {
    id: function(val){
      this.fetchMovie(val);
    }
  },
  created(){
    this.fetchMovie(this.id);
  },
  methods: {
    fetchMovie(){
      const url = [
          this.$store.state.tmdbapi.domain,
          this.media_type, '/', this.id,
          '?api_key=', this.$store.state.tmdbapi.apiKey,
          '&language=', this.$store.state.tmdbapi.language,
          '&append_to_response=credits'
      ].join('')
      // console.log(url, this.id, this.media_type)
      this.$axios.get(url, { withCredentials: false })
      .then(function(resp){
          // resp.data.backdrop_path = null
          this.movie = resp.data
          // console.log(this.movie)
          this.isFetched = true
      }.bind(this))
      .catch(function() {
        this.$router.push({ name: '404' });
      }.bind(this));
    }
  }
}
</script>

<style lang="scss">


.movie__detail {
  padding-top: 30px;
  padding-bottom: 100px;
  &.movie__detail--has-backdrop {
    padding-top: 500px;
    padding-top: 300px;
  }
  display: flex;

  .movie_detail--poster {
    width: 230px;
    flex-shrink: 0;
    flex-grow: 0;
    img {
      width: 100%;
    }
  }
  .movie_detail--content {
    padding: 0 30px;
    background-color: rgba($backgroundMain, 0.7);
    h1 {
      margin-top: 0;
      .year {
        color: #bbb;
        font-size: 0.5em;
      }
    }
  }

  .movie_detail--contentinner {
    display: flex;
    justify-content: space-between;
    &>div {
      min-width: 675px;
      flex-shrink: 1;
      flex-grow: 1;
    }
    .movie_detail--additional {
      width: 280px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

/* full version while at its peak size */
.enlargeable-image .full{
    background-color: rgba($backgroundMain, 0.9) !important;
}

</style>
