<template>
  <span :class="`${itemClass}`">
    <span @click="unsetFilter">Text: {{searchterm}} &#10005;</span>
  </span>
</template>

<script>
export default {
  name: 'TextFilter',
  data() {
    return {
      invert: false
    }
  },
  props: {
    searchterm: String,
    itemClass: {
      default: 'text-slug',
      type: String
    }
  },
  methods: {
    unsetFilter() {
      this.$store.commit('unsetFilter', {type: 'text'})
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
      this.$router.push(
        { name: 'Home' }
      )
    }
  }
}
</script>

<style lang="scss">

</style>
