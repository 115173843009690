<template>
  <div v-if="people.length" class="tabbed-content-block column-block">
    <h3><span>{{heading}}</span></h3>
    <div class="cast-list text-sluglist">
      <PersonLink v-for="person of people" v-bind:key="person.id" :person="person" />
    </div>
  </div>
</template>

<script>

import PersonLink from '@/components/Links/PersonLink.vue'
export default {
  name: 'CrewSection',
  components: {
    PersonLink
  },
  props: {
    people: Array,
    heading: String,
    itemClass: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss">
.column-block {
  display: flex;
  position: relative;
}
.column-block h3 {
    border-bottom: 1px dotted #456;
    margin-top: 14px;
    font-size: 1rem;
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-start;
}

.column-block h3 span {
    color: #789;
    font-weight: 400;
    font-size: .92307692rem;
    text-transform: uppercase;
    letter-spacing: .075em;
    position: relative;
    top: 3px;
    background-color: $backgroundMain;
    padding-right: .2em;
}
</style>
