<template>
  <div id="app">
    <div class="page">
      <header>
        <div class="header__inner" v-hotkey="keyBrowse">
          <router-link :to="{ name: 'Home'}">
            <img alt="Vue logo" src="./assets/logo-beta.png">
          </router-link>
          <GenreFilters />
          <TextFilter :searchterm="getState.filters.searchterm" v-if="getState.filters.searchterm !== ''"/>
          <input v-model="searchterm" @keyup="keymonitor" :placeholder="$t('text.search.placeholder')" ref="searchField"/>
          <span @click="resetFilters" v-if="getAnyFilterActive">Reset filters</span>
        </div>
        <div id="nprog-container"> </div>
      </header>
      <main>
        <router-view />
        <div class="backdrop"></div>
      </main>
    </div>
    <notifications group="foo" width="80%" position="bottom right" :duration="4000">
      <template slot="body" slot-scope="props">
        <div :class="`vue-notification-template vue-notification ${props.item.type} shadow1`">
        <div class="notification-content-wrapper">
            <div>
              <div class="notification-title">
                {{props.item.title}}
              </div>
              <div v-html="props.item.text" class="notification-content"></div>
            </div>
        </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
// import LoginForm from './components/LoginForm.vue'
import { mapGetters } from 'vuex'
import GenreFilters from './components/Filters/GenreFilters.vue'
import TextFilter from './components/Filters/TextFilter.vue'

export default {
  name: 'App',
  components: {
    // LoginForm,
    GenreFilters,
    TextFilter
  },
  data() {
    return {
      searchterm: ''
    }
  },
  created() {
    document.title = process.env.VUE_APP_TITLE
    this.$store.dispatch('fetchUserStatus')
    this.$store.commit('changeListStyle', 0)
    this.$store.dispatch('clientPaginationChange', 1)
  },
  computed: {
    ...mapGetters([
      'getState',
      'getAnyFilterActive'
    ]),
    keyBrowse () {
      return {
        'ctrl+space': this.focusSearchField
      }
    }
  },
  methods: {
    focusSearchField: function() {
      this.$refs.searchField.focus()
    },
    keymonitor: function(event) {
      // console.log(event.key);
      if(event.key == "Enter") {

        this.$refs.searchField.blur()
        this.searchterm = this.searchterm.trim()
        if(this.searchterm === '') {
          return
        }
        this.setFilter()
      }
    },
    setFilter: function() {
      this.$store.commit('clearResults')
      this.$store.commit('setFilter', {type: 'text', item: this.searchterm.trim() })
      this.$store.dispatch('clientPaginationChange', 1)
      this.searchterm = ''
    },
    resetFilters: function() {
      this.$store.commit('resetFilters')
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";

.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 0.3s ease 0s;
}
</style>
