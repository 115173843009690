<template>
  <div v-if="castList.length">
    <h3><span>Hauptdarsteller</span></h3>
    <div class="main__cast">
      <MainCastItem v-for="(person, idx) of castList" v-bind:key="`mci-${idx}`" :person="person" />
    </div>
  </div>
</template>

<script>

import MainCastItem from '@/components/MovieItem/MainCastItem.vue'
export default {
  name: 'MainCastCarousel',
  components: {
    MainCastItem
  },
  props: {
    castList: Array,
    itemClass: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss">
.main__cast {
  display: flex;
  &>* {
    width: 138px;
    margin: 0 15px 15px 0;
    font-size: 12px;
  }
  img {
    filter: grayscale(60%);
    opacity: 0.5;
    height: 175px;
    &:hover {
      -webkit-filter: none;
      filter: grayscale(0);
      opacity: 1;
    }
  }
  h4 {
    margin: 0;
  }
}
</style>
