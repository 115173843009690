<template>
  <div class="resulttype__changer">
    <span @click="setTypeMovie" :class="apiActive">
      {{$t(labelKey)}}
    </span>
    <span @click="setTypeMovie" :class="movieActive">
      {{$t('text.movies')}}
    </span>
    <span @click="setTypeTv" :class="tvActive">
      {{$t('text.tvs')}}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ResultTypeChanger',
  props: {
    apiModeMutation: {
      default: 'setApiToTmdb',
      type: String
    },
    apiMode: {
      default: 1,
      type: Number
    },
    labelKey: {
      default: 'text.all',
      type: String
    }
  },
  computed: {
    ...mapGetters([
      'getState'
    ]),
    movieActive() {
      if(this.getState.resultType === 'movie' && this.getState.apiMode === this.apiMode){
        return 'resulttype--active'
      }
      return ''
    },
    tvActive() {
      if(this.getState.resultType === 'tv' && this.getState.apiMode === this.apiMode){
        return 'resulttype--active'
      }
      return ''
    },
    apiActive() {
      if(this.getState.apiMode === this.apiMode){
        return 'resulttype--active'
      }
      return ''
    }
  },
  methods: {
    setTypeMovie() {
      let refetchList = false
      if (this.getState.apiMode !== this.apiMode) {
        refetchList = true
        this.$store.commit(this.apiModeMutation)
      }
      if (this.getState.resultType !== 'movie') {
        refetchList = true
        this.$store.commit('setResultTypeToMovie')
      }
      if(refetchList === false) {
        return
      }
      this.refetchList()
    },
    setTypeTv() {
      let refetchList = false
      if (this.getState.apiMode !== this.apiMode) {
        refetchList = true
        this.$store.commit(this.apiModeMutation)
      }
      if (this.getState.resultType !== 'tv') {
        refetchList = true
        this.$store.commit('setResultTypeToTv')
      }
      if(refetchList === false) {
        return
      }
      this.refetchList()
    },
    refetchList() {
      this.$store.commit('clearResults')
      this.$store.dispatch('clientPaginationChange', 1)
    }
  }
}
</script>

<style lang="scss">
  .resulttype__changer {
    color: #333;
  }
  .resulttype--active {
    color: #FFF;
  }
</style>
