export const extractYear = {
  methods: {
    extractYear(movie) {
        if(movie.release_date) {
            return movie.release_date.substring(0,4)
          }
          if(movie.first_air_date && movie.last_air_date) {
            return `${movie.first_air_date.substring(0,4)} - ${movie.last_air_date.substring(0,4)}`
          }
          if(movie.first_air_date) {
            return movie.first_air_date.substring(0,4)
          }
          return '????'
    }
  }
}
