<template>
  <div class="">
    <h1>Howdy {{getState.user.username}}</h1>
    <p>
      It seems you haven't imported your movies yet.<br>
      Download MoovyScanner.exe and give it a try...<br>
    </p>
    <h3>TODO:</h3>
    <pre>
add download link to scanner
write some instructions
especially that you have to use https://<strong class="red">api.</strong>moovy.at/ in your scanner configuration
    </pre>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HowToImport',
  computed: {
    ...mapGetters([
      'getState'
    ]),
  }
}
</script>

<style lang="scss">
  .red {
    color: $red;
  }
</style>
