import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import MovieDetail from '@/components/MovieDetail.vue'
import PersonDetail from '@/components/PersonDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE
    }
  },
  {
    path: '/detail/:media_type/:id',
    name: 'MovieDetail',
    component: MovieDetail,
    props: route => ({ id: parseInt(route.params.id), media_type: route.params.media_type })
  },
  {
    path: '/person/:id',
    name: 'PersonDetail',
    component: PersonDetail,
    props: route => ({ id: parseInt(route.params.id) })
  },
  {
    path: '/country/:id',
    name: 'Country',
    component: Home,
    props: route => ({ id: parseInt(route.params.id) })
  },
  
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
        behavior: 'smooth'
      }
    }
  }
})

export default router
