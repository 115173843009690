<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale" @change="changeLocale">
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">{{ lang }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  data () {
    return { langs: ['en', 'de'] }
  },
  methods: {
    changeLocale(event) {
      this.$root.$i18n.locale = event.target.value
      this.$store.commit('changeLocale', event.target.value)
    }
  }
}
</script>
