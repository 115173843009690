import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex' 
import store from './store'
import router from './router'
import Notifications from 'vue-notification'
import VueHotkey from 'v-hotkey'
import NProgress from '../node_modules/nprogress/nprogress.js'

import { I18N_EN } from './i18n/en';
import { I18N_DE } from './i18n/de';

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(VueI18n);
Vue.use(Notifications)
Vue.use(VueHotkey)
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'de',
  messages: {
    en: I18N_EN,
    de: I18N_DE
  }
})

NProgress.configure({
  showSpinner: false,
  parent: "#nprog-container",
  speed: 100,
  trickleRate: 0.02,
  trickleSpeed: 800
});


const axiosInstance = axios.create()

// before a request is made start the nprogress
axiosInstance.interceptors.request.use(config => {
  NProgress.start()
  document.querySelector('.backdrop').style.visibility = 'visible';
  // document.querySelector('.backdrop').style.opacity = 0.5;
  return config
})

// before a response is returned stop nprogress
axiosInstance.interceptors.response.use(response => {
  NProgress.done()
  // document.querySelector('.backdrop').style.opacity = 0;
  document.querySelector('.backdrop').style.visibility = 'hidden';
  return response
})

Vue.prototype.$axios = axiosInstance;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
