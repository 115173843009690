<template>
  <div class="content">
    <aside>
      <ul>
        <li>
          <LocaleChanger />
        </li>
        <li>
          <ResultTypeChanger apiModeMutation="setApiToMoovy" :apiMode="2" labelKey="text.my" />
        </li>
        <li>
          <ResultTypeChanger />
        </li>
        <li>
          listen
        </li>
        <li>
          einstellungen
        </li>
        <li>
          info site
        </li>
        <li v-if="isLoggedIn">
          <button type="button" @click="logout()">Logout</button> {{getState.user.username}}
        </li>
      </ul>
      <a href="https://www.themoviedb.org/" target="_blank" class="tmdb__link" title="Visit www.themoviedb.org">
        source of data &amp;<br>images is TMDB
        <img alt="Vue logo" src="../assets/img/tmdb.svg" />
      </a>
    </aside>
    <main>
      <MovieResultList />
    </main>
    <router-view />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import MovieResultList from '@/components/MovieResultList.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'
import ResultTypeChanger from '@/components/ResultTypeChanger.vue'
export default {
  name: 'Duels',
  components: {
    MovieResultList,
    LocaleChanger,
    ResultTypeChanger
  },
  methods: {
    logout() {
      this.$axios.post(
        `${process.env.VUE_APP_API_URL}userdata`,
        this.toUrlEncoded({
          logintype: 'logout'
        }),
        { withCredentials: true }
      )
      .then(response => {
        this.$store.commit('clearResults')
        this.$store.commit('receiveUserData', response)
        this.$store.dispatch('clientPaginationChange', 1)
      })
    },
    toUrlEncoded(obj) {
      return Object.keys(obj).map(
        k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
      ).join('&')
    }
  },
  computed: {
    ...mapGetters([
      'getState',
      'isLoggedIn'
    ])
  },
  created() {
    this.$store.dispatch('fetchApiData')
  }
}
</script>

<style lang="scss">
.tmdb__link {
  margin-top: 200px;
  display: block;
  opacity: 0.3;
  img {
    margin-top: 20px;
    width: 100px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
