<template>
  <div class="backdrop-container" v-if="backdropPath">
    <div class="backdrop-wrapper ">
      <div
        class="backdropimage js-backdrop-image"
        :style="`background-image: url(&quot;https://image.tmdb.org/t/p/w1280${backdropPath}&quot;);`"></div>
      <div class="backdropmask js-backdrop-fade"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Backdrop',
  props: {
    backdropPath: String
  }
}
</script>

<style lang="scss">

// $backdropWidth: 1200px;
// $backdropHeight: 675px;

$backdropWidth: 960px;
$backdropHeight: 540px;



.backdrop-container {
    position: absolute;
    z-index: -1;
    top: $headerHeight;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: $backdropHeight;
}
.backdrop-wrapper {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: $backdropWidth;
    height: $backdropHeight;
}

.backdrop-image {
  background-position: center -121px;
  background-position: center -23px; 
}

.backdrop-wrapper.-loaded>.backdropimage {
    opacity: 1;
}

.backdrop-wrapper>.backdropimage, .backdrop-wrapper>.backdropplaceholder {
    position: absolute;
    width: 100%;
    height: 99.95%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.backdrop-wrapper>.backdropmask {
    padding-top: 500px;
    position: relative;
}

.backdrop-wrapper>.backdropmask:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    pointer-events: none;
    width: $backdropWidth;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: $backdropHeight;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      rgba($backgroundMain, 1)     0,
      rgba($backgroundMain, .9855) .97%,
      rgba($backgroundMain, .9451) 2.0783%,
      rgba($backgroundMain, .8830) 3.2966%,
      rgba($backgroundMain, .8034) 4.6016%,
      rgba($backgroundMain, .7109) 5.9666%,
      rgba($backgroundMain, .6095) 7.365%,
      rgba($backgroundMain, .5037) 8.7716%,
      rgba($backgroundMain, .3977) 10.16%,
      rgba($backgroundMain, .2959) 11.505%,
      rgba($backgroundMain, .2026) 12.78%,
      rgba($backgroundMain, .1220) 13.9583%,
      rgba($backgroundMain, .0585) 15.0166%,
      rgba($backgroundMain, .0164) 15.9283%,
      rgba($backgroundMain, 0)     16.6666%,
      rgba($backgroundMain, 0)     83.3333%,
      rgba($backgroundMain, .0164) 84.0716%,
      rgba($backgroundMain, .0585) 84.9833%,
      rgba($backgroundMain, .1220) 86.0416%,
      rgba($backgroundMain, .2026) 87.22%,
      rgba($backgroundMain, .2959) 88.495%,
      rgba($backgroundMain, .3977) 89.84%,
      rgba($backgroundMain, .5037) 91.2283%,
      rgba($backgroundMain, .6095) 92.635%,
      rgba($backgroundMain, .7109) 94.0333%,
      rgba($backgroundMain, .8034) 95.3983%,
      rgba($backgroundMain, .8830) 96.7033%,
      rgba($backgroundMain, .9451) 97.9216%,
      rgba($backgroundMain, .9855) 99.03%,
      rgba($backgroundMain, 1)
    ),
    linear-gradient(
      0deg,
      rgba($backgroundMain, 1) 0,
      rgba($backgroundMain, 1) 21.4814%,
      rgba($backgroundMain, .9855) 23.6370%,
      rgba($backgroundMain, .9451) 26.1%,
      rgba($backgroundMain, .8830) 28.8074%,
      rgba($backgroundMain, .8034) 31.7074%,
      rgba($backgroundMain, .7109) 34.7407%,
      rgba($backgroundMain, .6095) 37.8481%,
      rgba($backgroundMain, .5037) 40.9740%,
      rgba($backgroundMain, .3977) 44.0592%,
      rgba($backgroundMain, .2959) 47.0481%,
      rgba($backgroundMain, .2026) 49.8814%,
      rgba($backgroundMain, .1220) 52.5%,
      rgba($backgroundMain, .0585) 54.8518%,
      rgba($backgroundMain, .0164) 56.8777%,
      rgba($backgroundMain, 0) 58.5185%
    );
}

</style>
